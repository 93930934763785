import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faStore, faHandshake, faUserSecret, faKey, faHistory, faGear, faServer } from '@fortawesome/free-solid-svg-icons';



type IMenu = {
  type?: "group" | "submenu";
  key?: string;
  label: string;
  icon?: any;
  permission?: boolean | string[];
  description?: string;

  children?: IMenu[];
};
export const MENUS: IMenu[] = [
  {
    key: "/dashboard",
    label: "Dashboard",
    icon: <FontAwesomeIcon icon={faHistory} />,
    permission: true,
    description: "See insight here",
  },
  {
    key: "/transaction",
    label: "Transaction",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
    permission: ['TRANSACTION:LIST'],
    description: "See insight here",
  },
  {
    key: "/merchant",
    type: "submenu",
    label: "Merchant",
    icon: <FontAwesomeIcon icon={faStore} />,
    permission: true,
    children: [
      {
        key: "/merchant/add",
        label: "Add",
        permission: ['MERCHANT-APPLICATION:CHECK', 'MERCHANT-APPLICATION:INQUIRY'],
      },
      {
        key: "/merchant/onboarding",
        label: "Onboarding",
        permission: ['MERCHANT-APPLICATION:GET'],

      },
      {
        key: "/merchant/profile",
        label: "Profile",
        permission: ['MERCHANT:LIST'],
      },
    ],
  },
  {
    key: "/settlement",
    type: "submenu",
    label: "Settlement",
    icon: <FontAwesomeIcon icon={faHandshake} />,
    permission: true,
    children: [
      {
        key: "/settlement/reconciliation",
        label: "Reconciliation",
        permission: ['SETTLEMENT:GET'],
      },
      {
        key: "/settlement/reports",
        label: "Reports",
        permission: ['SETTLEMENT:GET'],

      },
      {
        key: "/settlement/settings",
        label: "Settings",
        permission: ['SETTLEMENT:GET'],
      },
    ],
  },
  {
    key: "/fds",
    type: "submenu",
    label: "FDS",
    icon: <FontAwesomeIcon icon={faUserSecret} />,
    permission: true,
    children: [
      {
        key: "/fds/monitor",
        label: "Monitor",
        permission: ['FDS:LIST'],
        // permission: true
      },
      {
        key: "/fds/setting",
        label: "Setting",
        permission: ['FDS-CONFIG:GET'],
        // permission: true
      },
    ],
  },
  {
    key: "/access",
    type: "submenu",
    label: "Access",
    icon: <FontAwesomeIcon icon={faKey} />,
    permission: true,
    children: [
      {
        key: "/access/role",
        label: "Role",
        permission: ['ROLE:LIST'],
      },
      {
        key: "/access/ojirian",
        label: "Ojirian",
        permission: ['ADMIN:LIST'],

      },
    ],
  },
  {
    key: "/audit",
    type: "submenu",
    label: "Audit",
    icon: <FontAwesomeIcon icon={faHistory} />,
    permission: true,
    children: [
      {
        key: "/audit/merchant-log",
        label: "Merchant Log",
        permission: ['AUDIT:LIST'],
      },
      {
        key: "/audit/ojirian-log",
        label: "Ojirian Log",
        permission: ['AUDIT:LIST'],
      },
    ],
  },
  {
    key: "/configuration",
    label: "Configuration",
    icon: <FontAwesomeIcon icon={faGear} />,
    permission: ['CONFIGURATION:LIST'],
  },
  {
    key: "/server",
    label: "Server",
    icon: <FontAwesomeIcon icon={faServer} />,
    permission: ['SERVER:LIST'],
  },
];

export const getFlatMenus = (menus: IMenu[]) => {
  return menus
    .reduce((acc, curr) => {
      if (curr.children) {
        curr.children.forEach((child: IMenu) => {
          acc.push(child);

          if (child.children) {
            child.children.forEach((child2: IMenu) => {
              acc.push(child2);
            });
          }
        });
      }
      acc.push(curr);
      return acc;
    }, [] as any[])
    .filter((item: any) => item.key)
    .filter((item: any) => item.type !== "group");
};

export const MENUS_FLAT = getFlatMenus(MENUS);
console.info("menus_flat", MENUS_FLAT);

export const getMenu = () => {
  return MENUS_FLAT.find(
    (item: any) => item.key === window?.location?.pathname
  );
};
