import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../screens/layout/AppLayout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Spin } from "antd";
import Logout from "../screens/auth/Logout";
const NotFound = React.lazy(() => import("../NotFound"));

const Login = React.lazy(() => import("../screens/auth/NewLogin"));
const Customer = React.lazy(() => import("../screens/customer"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));

const Dashboard = React.lazy(() => import("../screens/dashboard"));
const ChangePasswordUser = React.lazy(
  () => import("../screens/user/ChangePassword")
);
const ChangeMyPassword = React.lazy(
  () => import('../screens/auth/ChangeMyPassword')
);
const User = React.lazy(() => import("../screens/user"));
const UserDetail = React.lazy(() => import("../screens/user/Detail"));;

const Transaction = React.lazy(() => import("../screens/transaction"));
const MerchantProfile = React.lazy(() => import("../screens/merchant/Profile"));
const MerchantAdd = React.lazy(() => import("../screens/merchant/Add"));
const MerchantOnboarding = React.lazy(() => import("../screens/merchant/Onboarding"));
const MerchantDetail = React.lazy(() => import("../screens/merchant/Detail"));

const SettlementReconciliation = React.lazy(() => import("../screens/settlement/Reconciliation"));
const SettlementReports = React.lazy(() => import("../screens/settlement/Reports"));
const SettlementSettings = React.lazy(() => import("../screens/settlement/Settings"));

const FDSMonitor = React.lazy(() => import("../screens/fds/Monitor"));
const FDSSetting = React.lazy(() => import("../screens/fds/Setting"));


const AccessRole = React.lazy(() => import("../screens/access/Role"));
const AccessOjirian = React.lazy(() => import("../screens/access/Ojirian"));

const AuditMerchant = React.lazy(() => import("../screens/audit/MerchantLog"));
const AuditOjirian = React.lazy(() => import("../screens/audit/OjirianLog"));

const Server = React.lazy(() => import("../screens/server/"));


const Configuration = React.lazy(() => import("../screens/configuration/Configuration"));

const RootNavigator: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/customer"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Customer />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ForgotPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:sessionId"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ResetPassword />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />

        <Route element={<AppLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />

          <Route
            path="/transaction"
            element={
              <PrivateRoute loginPath="/login">
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            path="/merchant/add"
            element={
              <PrivateRoute loginPath="/login">
                <MerchantAdd />
              </PrivateRoute>
            }
          />
          <Route
            path="/merchant/onboarding"
            element={
              <PrivateRoute loginPath="/login">
                <MerchantOnboarding />
              </PrivateRoute>
            }
          />
          <Route
            path="/merchant/onboarding/:merchantId"
            element={
              <PrivateRoute loginPath="/login">
                <MerchantDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/merchant/profile"
            element={
              <PrivateRoute loginPath="/login">
                <MerchantProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/merchant/profile/:merchantId"
            element={
              <PrivateRoute loginPath="/login">
                <MerchantDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/settlement/reconciliation"
            element={
              <PrivateRoute loginPath="/login">
                <SettlementReconciliation />
              </PrivateRoute>
            }
          />
          <Route
            path="/settlement/reports"
            element={
              <PrivateRoute loginPath="/login">
                <SettlementReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/settlement/settings"
            element={
              <PrivateRoute loginPath="/login">
                <SettlementSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/fds/monitor"
            element={
              <PrivateRoute loginPath="/login">
                <FDSMonitor />
              </PrivateRoute>
            }
          />
          <Route
            path="/fds/setting"
            element={
              <PrivateRoute loginPath="/login">
                <FDSSetting />
              </PrivateRoute>
            }
          />
          <Route
            path="/access/role"
            element={
              <PrivateRoute loginPath="/login">
                <AccessRole />
              </PrivateRoute>
            }
          />
          <Route
            path="/access/ojirian"
            element={
              <PrivateRoute loginPath="/login">
                <AccessOjirian />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit/merchant-log"
            element={
              <PrivateRoute loginPath="/login">
                <AuditMerchant />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit/ojirian-log"
            element={
              <PrivateRoute loginPath="/login">
                <AuditOjirian />
              </PrivateRoute>
            }
          />
          <Route
            path="/server"
            element={
              <PrivateRoute loginPath="/login">
                <Server />
              </PrivateRoute>
            }
          />
          <Route
            path="/configuration"
            element={
              <PrivateRoute loginPath="/login">
                <Configuration />
              </PrivateRoute>
            }
          />
          <Route
            path="/logout"
            element={
              <PublicRoute>
                <Logout />
              </PublicRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
