import { Image, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import config, { APP_LOGO_SRC, APP_LOGO_SRC_COLLAPSED } from '../const/config';

type AppLogoProps = {
  collapsed: boolean;
};

const { Text } = Typography;

const AppLogo: React.FC<AppLogoProps> = ({ collapsed }) => {
  return (
    <LogoContainer collapsed={collapsed}>
      {collapsed && config.sidebar.logoType === 'image-and-text' ? (
        // <AppAvatar size="large">APP</AppAvatar>
        <Image
          preview={false}
          width={80}
          src={APP_LOGO_SRC_COLLAPSED}
          style={{
            objectFit: 'cover',
          }}
        />
      ) : (
        <AppTitle>
          {config.sidebar.logoType === 'image-and-text' && (
            <Image
              preview={false}
              width={120}
              src={APP_LOGO_SRC}
              style={{
                objectFit: 'cover',
              }}
            />
          )}

          <Text style={{ fontSize: 16, color: '#FFFFFF' }}>
            {process.env.REACT_APP_WEBSITE_NAME}
          </Text>
        </AppTitle>
      )}
    </LogoContainer>
  );
};

type LogoContainerProps = {
  collapsed: boolean;
};

const LogoContainer = styled.div<LogoContainerProps>`
  padding: ${({ collapsed }) => (collapsed ? '0' : '3rem 16px')};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
`;

const AppTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default AppLogo;
