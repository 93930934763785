import RootNavigator from "./navigation/RootNavigator";
import "./assets/app.css";
import "antd/dist/reset.css";
import ConfigProvider from "./context/ConfigProvider";
import { Alert } from 'antd'
import { theme } from "./assets/theme";
import { AuthProvider } from "react-auth-kit";
import "./i18n";
import { ThemeProvider } from "styled-components";

const { ErrorBoundary } = Alert;
function App() {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider
            authType="localstorage"
            authName={'_auth'}
          // cookieDomain={window.location.hostname}
          // cookieSecure={window.location.protocol === "https:"}
          >
            <RootNavigator />
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
