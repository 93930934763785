import { useAuthUser } from 'react-auth-kit';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';

export default function usePermission() {
  const auth = useAuthUser();
  const navigate = useNavigate()
  const user = auth()

  if(!user?.token){
    navigate('/login')
    const isUserHasPermission = (
      requiredPermissions: string[], 
      operator: "OR" | "AND" = "OR"
    ): boolean => {
     return false
    };
    return {
      isUserHasPermission,
    };
  }
  const decoded: any = jwtDecode(user?.token);
  console.log((decoded.scope))
  const isUserHasPermission = (
    requiredPermissions: string[], 
    operator: "OR" | "AND" = "OR"
  ): boolean => {
    if (operator === "AND") {
      return requiredPermissions.every(permission =>  decoded.scope.includes(permission));
    } else {
      return requiredPermissions.some(permission => decoded.scope.includes(permission));
    }
  };

  return {
    isUserHasPermission,
  };
}