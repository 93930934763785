import { getToken } from '@qlibs/utils';
import { AxiosInstance } from 'axios';
// import { getToken } from './auth';

export function injectAxiosInstance(
  axiosInstance: AxiosInstance,
  contentType: 'application/json' | 'multipart/form-data' | string,
  Modal: any
) {
  axiosInstance.interceptors.request.use(
    async (config: any) => {
      config.headers['Content-Type'] = contentType || 'application/json';
      config.headers['Authorization'] = 'Bearer ' + getToken();
      return config;
    },
    (error) => {
      console.error(
        'axiosInstance: Error interceptor request:::',
        error.response
      );
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error(error);
      if (error && error.response) {
        handleNoAuth(error.response, Modal);
        console.error(
          'axiosInstance: Error interceptor response:::',
          error.response
        );
        return Promise.reject(error.response);
      } else {
        console.error(error);
      }
    }
  );

  return axiosInstance;
}

function handleNoAuth(errorResponse: any, Modal: any) {
  if (
    errorResponse.status === 403 ||
    errorResponse.status === 401 ||
    errorResponse.data?.code === 'err_unauthorized'
  ) {
    clear();
    let title, content;
    if (errorResponse.status === 403) {
      title = 'Forbidden';
      content = `You don't have permission. Please contact administrator.`;
    } else if (errorResponse.status === 401) {
      title = 'Unauthorized';
      content = `Please login to continue`;
    } else if (errorResponse.data?.code === 'err_unauthorized') {
      title = 'Unauthorized.';
      content = `Please login to continue`;
    }

    if (!isModalVisible()) {
      Modal.error({
        className: 'noauth_401403',
        title,
        content,
        onOk: () => {
          window.location.href = '/login';
        },
        onCancel: () => {
          window.location.href = '/login';
        },
      });
    }
  }
}

function clear() {
  // removeToken();
  localStorage.clear();
}

const isModalVisible = () => {
  return !!document.querySelector('.noauth_401403');
};
