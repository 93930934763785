import {
  DownOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  MenuProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateInitialFromName } from "../helpers/generator";
import { getFullName } from "../helpers/name";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { capitalizeFirstLetter } from "@qlibs/react-components";
import { ListLangType } from "../i18n/settings";
import { useCookies } from "react-cookie";
import { useTranslationCustom } from "../i18n/client";
import useLanguage from "../zustand/useLanguage";
const UserAvatar = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const auth = useAuthUser();
  console.info("auth()", auth());
  // const lng = useSelector((state: any) => state.language.language);
  const { lng, setLng } = useLanguage();

  console.log(`zustand: ${lng}`);

  const [cookies, setCookie] = useCookies(["i18next"]);

  const [languageSelected, setLanguageSelected] = useState<ListLangType>(lng);

  const { i18n } = useTranslationCustom(lng, "language");

  const initial = generateInitialFromName(auth()?.name!);
  // const role = capitalizeFirstLetter(auth()?.userType!);
  let name = auth()?.name ? getFullName(auth()) : "";
  name = name
    ? name
      .split(" ")
      .map((value: any) => capitalizeFirstLetter(value))
      .join(" ")
    : "";

  const handleMenuClick = (e: any) => {
    if (e.key === "profile") {
      navigate("/profile");
    } else if (e.key === "change-password") {
      navigate("/change-password");
    } else {
      signOut();
      navigate("/login");
    }
  };

  const items: MenuProps = {
    onClick: handleMenuClick,
    items: [
      {
        key: "1",
        type: "group",
        label:
          "Welcome, " +
          (auth()?.name!.length > 15
            ? auth()?.name!.substring(0, 15) + "..."
            : auth()?.name),
        children: [
          {
            key: "profile",
            icon: <UserOutlined />,
            label: "My Profile",
            disabled: true
          },
          {
            key: "change-password",
            icon: <LockOutlined />,
            label: "Change Password",
          },
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    i18n.changeLanguage(languageSelected);
    setCookie("i18next", languageSelected);
    setLng(languageSelected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSelected]);

  useEffect(() => {
    if (cookies?.i18next) {
      setLanguageSelected(cookies?.i18next);
    }
  }, [cookies?.i18next]);

  return (
    <div
      style={{
        display: "flex",
        gap: 14,
        width: "100%",
        alignItems: "center",
      }}
    >
      <Dropdown menu={items}>
        <div
          style={{
            display: "flex",
            gap: 8,
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              lineHeight: 1.5,
            }}
          >
            <span
              style={{
                marginBottom: 0,
                fontSize: 12,
                color: "#768499",
              }}
            >
              {/* {role} */}
            </span>
            <span
              style={{
                marginTop: 0,
                marginBottom: 0,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {name}
            </span>
          </div>
          <AppAvatar size="large">{initial}</AppAvatar>

          <Icon />
        </div>
      </Dropdown>
    </div>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: #e6f3fa;
  color: #00aeef;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 600;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;
