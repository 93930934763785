import { capitalizeFirstLetter } from '@qlibs/react-components';
import { Breadcrumb } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useDetailBreadcrumbs from '../hooks/useDetailBreadcrumbs';

interface IProps {
  marginTop?: number;
}


const Breadcrumbs: React.FC<IProps> = ({ marginTop }) => {
  const { breadcrumbDetails } = useDetailBreadcrumbs(); 
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  const capitalizedBreadcrumbs = ['fds'];

  const renderLabel = (label: any) => {
    const labels = label.props.children.split('-');
    if (labels.length > 0) {
      const findInBreadcrumbDetails = breadcrumbDetails.find(
        (item) =>
          item.value.split('-').join(' ').toLowerCase() ===
          label.props.children.toLowerCase(),
      );
      if (findInBreadcrumbDetails) {
        return findInBreadcrumbDetails.label;
      } else {
        let newLabel = labels.join(' ').toString();
        return newLabel.length > 20
          ? newLabel.substr(0, 20) + '...'
          : newLabel
            .split(' ')
            .map((value: any) => capitalizedBreadcrumbs.includes(value.trim().toLowerCase()) ? value.toUpperCase() : capitalizeFirstLetter(value))
            .join(' ');
      }
    } else {
      return label.props.children;
    }
  };

  const handleBreadcrumb = (e: any, link: string, locationState: any) => {
    e.preventDefault();

    navigate(link, {
      state: {
        ...locationState,
      },
    });
  };

  return (
    <>
      {(breadcrumbs.length > 0) && (
        <Breadcrumb style={{ marginTop, color: '#C2C2C2', fontWeight: 500 }} separator=">">
          {breadcrumbs.slice(1).map(({ breadcrumb, match, location }, index) => {
            const isLastItem = index === breadcrumbs.slice(1).length - 1;
            return (
              <Breadcrumb.Item key={match.pathname}>
                <div
                  onClick={(e) => {
                    const path = match.pathname;
                    handleBreadcrumb(e, path, location.state);
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    height: 22,
                    fontWeight: 500,
                    color: isLastItem ?  '#00AEEF' : '#C2C2C2',
                  }}
                >
                  {renderLabel(breadcrumb)}
                </div>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      )}


    </>
    // <Breadcrumb style={{ marginTop }} separator=">">
    //   {breadcrumbs.slice(1).map(({ breadcrumb, match, location }, index) => (

    //     <Breadcrumb.Item key={match.pathname}>
    //       <div
    //         onClick={(e) => {
    //           const path = match.pathname;
    //           handleBreadcrumb(e, path, location.state);
    //         }}
    //         style={{ display: 'flex', alignItems: 'start', height: 22 }}
    //       >
    //         {renderLabel(breadcrumb)}
    //       </div>
    //     </Breadcrumb.Item>
    //   ))}
    // </Breadcrumb>

  );
};

export default Breadcrumbs;
