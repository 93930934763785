import React from 'react';
import styled from 'styled-components';
import AuthContentLeft from './AuthContentLeft';
import AuthContentLeftSecondary from './AuthContentLeftSecondary';
import AuthContentRight from './AuthContentRight';
import { httpRequest } from '../../helpers/api';
import { MainCheckHealth } from '@qlibs/react-components';

interface Props {
  variant?: 'primary' | 'secondary';
  formPosition?: 'left' | 'right' | 'middle';
  children?: any;
}

const AuthLayout: React.FC<Props> = ({
  children,
  variant = 'primary',
  formPosition = 'right',
}) => {
  return (
    <div>
      {!!process.env.REACT_APP_CHECK_HEALTH_URL && (
        <MainCheckHealth url={process.env.REACT_APP_CHECK_HEALTH_URL || '/'} axiosInstance={httpRequest as any} />
      )}
      {formPosition !== 'middle' ? (
        <Container>
          {formPosition === 'right' ? (
            <>
              {variant === 'primary' ? (
                <AuthContentLeft />
              ) : (
                <AuthContentLeftSecondary />
              )}
              <ContentRight>{children}</ContentRight>
            </>
          ) : (
            <>
              <ContentRight>{children}</ContentRight>
              <AuthContentRight />
            </>
          )}
        </Container>
      ) : (
        <>
          <ContainerMiddle>
            {children}
          </ContainerMiddle>
        </>

      )}
    </div>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
`;

const ContainerMiddle = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  background: linear-gradient(to bottom, #4e73df, #234bbe);
   grid-template-rows: 1fr auto;
`;


const ContentRight = styled.div`
  display: flex;
  align-items: center;
  background-color: red;
  justify-content: center;
`;

export default AuthLayout;
