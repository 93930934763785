import { Suspense, useState } from "react";
import {
  Divider,
  Layout,
  Space,
  Spin,
  Typography,
} from "antd";
import {
  MenuFoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import UserAvatar from "../../components/UserAvatar";
import useConfigApp from "../../hooks/useConfigApp";
import AppLogo from "../../components/AppLogo";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { httpRequest } from "../../helpers/api";
import {
  IdleHandler,
} from "@qlibs/react-components";
import { MENUS_FLAT } from "../../const/menu";
import config from "../../const/config";
import { isMobile } from "react-device-detect";

const { Header, Content } = Layout;

const AppLayout = () => {
  const navigate = useNavigate();
  const { sidebarCollapsed } = useConfigApp();
  const location = useLocation();
  const auth = useAuthUser();
  const signOut = useSignOut();

  const [sidebarShow, setSidebarShow] = useState(isMobile ? false : true);

  return (
    <>
      <div>
        <IdleHandler
          pingUrl={process.env.REACT_APP_CHECK_HEALTH_URL || "/"}
          axiosInstance={httpRequest as any}
          isLoggedIn={auth() ? true : false}
          idleTimeout={undefined}
          sessionTimeout={undefined}
          callbackOnError={() => {
            signOut();
            navigate("/login");
          }}
        >
          {" "}
        </IdleHandler>
      </div>
      <Layout style={{ height: "100vh", flexDirection: "row" }}>
        <Layout
          id="scroll-content"
          className="site-layout"
          style={{ height: "100vh", overflowY: "hidden", display: 'flex', flexDirection: 'column' }}
        >
          <AppHeader>
            {isMobile && sidebarShow ? (
              <span
                style={{ cursor: "pointer", padding: 5 }}
                onClick={() => setSidebarShow(false)}
              >
                <MenuFoldOutlined style={{ fontSize: 20 }} />
              </span>
            ) : isMobile ? (
              <span
                style={{ cursor: "pointer", padding: 5 }}
                onClick={() => setSidebarShow(true)}
              >
                <MenuOutlined style={{ fontSize: 20 }} />
              </span>
            ) : (
              false
            )}
            {config.pageTitlePosition === "top-nav" &&
              !isMobile &&
              MENUS_FLAT.map((item: any) => {
                if (item.key === location.pathname) {
                  return (
                    <>
                      <AppHeaderLabel>
                        {item.icon}
                        <Typography.Title
                          level={3}
                          style={{ marginBottom: 0, fontSize: 18 }}
                        >
                          {item.label}
                        </Typography.Title>
                        <Divider type="vertical" />
                        <Typography.Text style={{ color: "darkgrey" }}>
                          {item.description}
                        </Typography.Text>
                      </AppHeaderLabel>
                    </>
                  );
                } else {
                  return false;
                }
              })}
            <AppLogo collapsed={sidebarCollapsed} />

            <AppHeaderAccount>
              <DateNow>
                <b style={{color: '#757575'}}>{auth()?.firstName || auth()?.name}</b>{" "}
              </DateNow>
              <UserAvatar />
            </AppHeaderAccount>
          </AppHeader>
          <div style={{ display: 'flex', backgroundColor: 'blue' }}>

            {sidebarShow && (
              <div>
                <Sidebar />
              </div>
            )}

            <AppContent>
              <Space size="middle" direction="vertical">
                {config.pageTitlePosition === "content" && !isMobile && (
                  <Breadcrumbs marginTop={10} />
                )}

                <Suspense fallback={<Spin spinning={true} />}>
                  <Outlet />
                </Suspense>
              </Space>

            </AppContent>
          </div>

        </Layout>
      </Layout>
    </>
  );
};

const AppContent = styled(Content)`
  height: 100vh;
  padding: 20px 20px 150px 20px;
  background-color: #f8f9fc;
  overflow-y: scroll;
`;

const AppHeader = styled(Header)`
  background-color: #ffffff;
  position: sticky;
  top: 0;
  padding: 0 20px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  border-bottom: 1px solid #c2c2c2;

`;

const DateNow = styled.div`
  color: '#757575';
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: ${isMobile ? undefined : "1fr auto auto"};
  align-items: center;
  justify-content: flex-end;
`;

const AppHeaderLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;


export default AppLayout;
